import React from "react"
const ReactMarkdown = require("react-markdown")

function ProjectAbout(props) {
  const { about, link_url } = props
  return (
    <div className="project-description">
      <ReactMarkdown
        className="markdown"
        source={about}
        renderers={{
          link: props => (
            <a href={props.href} target="_blank">
              {props.children}
            </a>
          )
        }}
      />
      {link_url && (
        <a
          href={link_url}
          target="_blank"
          className="text-uppercase skewHover d-inline-block"
        >
          ↳ {link_url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0]}
        </a>
      )}
    </div>
  )
}

export default ProjectAbout

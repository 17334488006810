import React from "react"
import ProjectOpening from "./ProjectOpening"

const ProjectOpenings = React.memo(function ProjectOpenings(props) {
  const { appdata, isMobile, filter } = props
  let projectsOpenings = []
  const projectnames = appdata.projects.children
  projectnames.map((projectname, index) => {
    const project = appdata[projectname]
    // let projectOrder = project["num"]
    if (project.content != false) {
      if (filter) {
        const updatedFilter = filter.replace("\u00A0", " ")
        const tags = project.content.tags.split(", ")
        if (tags.includes(updatedFilter)) {
          projectsOpenings.push(
            <ProjectOpening
              hoverProject={props.hoverProject}
              unhoverProject={props.unhoverProject}
              openProject={props.openProject}
              requestProjectChange={props.requestProjectChange}
              key={index}
              project={project}
              isMobile={isMobile}
            />
          )
        }
      } else {
        projectsOpenings.push(
          <ProjectOpening
            hoverProject={props.hoverProject}
            unhoverProject={props.unhoverProject}
            openProject={props.openProject}
            requestProjectChange={props.requestProjectChange}
            key={index}
            project={project}
            isMobile={isMobile}
          />
        )
      }
    }
  })
  return <>{projectsOpenings}</>
})

export default ProjectOpenings

import React from "react"
import ProjectAbout from "./ProjectAbout"

function ProjectHeader(props) {
  const { year, projectname, link_url, about } = props.content
  const tags = props.content.tags
    .split(", ")
    .map(function(string) {
      return string.replace(/\s+/g, "\u00A0")
    })
    .join(" . ")
  const { isMobile } = props
  // window.content = props.content

  if (isMobile) {
    return (
      <>
        <div className="project-meta">
          {/* <p className="project-meta__years mb-0">{year}</p> */}
          <h1 className="mb-0 text-uppercase">{projectname}</h1>
          <ProjectAbout about={about} link_url={link_url} />
          <p className="project-meta__tags small">{tags}</p>
        </div>
      </>
    )
  } else {
    return (
      <div className="project-meta">
        <div className="d-flex">
          <ProjectAbout about={about} link_url={link_url} />
          {/* <p className="project-meta__years text-right">{year}</p> */}
        </div>
        <p className="project-meta__tags small">{tags}</p>
      </div>
    )
  }
}

export default ProjectHeader

import React from "react"
import Subprojects from "./project/Subprojects"
import ProjectContainer from "./project/ProjectContainer"
import ProjectFooter from "./project/ProjectFooter"

class Project extends React.Component {
  constructor(props) {
    super(props)
    const regex = new RegExp("^projects/[^/]*$", "g")
    const allowed = Object.keys(appdata).filter(v => v.match(regex))
    const filtered = Object.keys(appdata)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = appdata[key]
        return obj
      }, {})
    const secondFiltered = Object.values(filtered).filter(
      v => v.content != false
    )
    this.state = {
      projectsData: secondFiltered
    }
  }

  shouldComponentUpdate(nextProps) {
    const { isMobile, project, active } = this.props
    const {
      isMobile: nextIsMobile,
      project: nextProject,
      active: nextActive
    } = nextProps
    return (
      isMobile != nextIsMobile || project != nextProject || active != nextActive
    )
  }

  render() {
    const { project, isMobile, appdata, footertext, english } = this.props
    const { projectsData } = this.state
    if (project != null) {
      return (
        <ProjectContainer {...this.props}>
          {project.children.length > 0 ? (
            <>
              <Subprojects
                isMobile={isMobile}
                project={project}
                appdata={appdata}
              />
              <ProjectFooter
                text={footertext}
                project={project}
                similar={false}
                isMobile={isMobile}
              />
            </>
          ) : (
            <ProjectFooter
              text={footertext}
              projectsData={projectsData}
              project={project}
              isMobile={isMobile}
              similar={true}
              openProject={this.props.openProject}
            />
          )}
        </ProjectContainer>
      )
    } else {
      return <></>
    }
  }
}

export default Project

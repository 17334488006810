import React, { useState, useEffect } from "react"
var classNames = require("classnames")

function CustomImg(props) {
  const [loaded, setLoaded] = useState(false)
  const [source, setSrc] = useState("")
  const { src } = props
  useEffect(() => {
    if (source != src) {
      setLoaded(false)
      setSrc(src)
    }
  })

  const clnms = classNames({
    "img-preload": true,
    loaded: loaded
  })

  return (
    <div className={clnms}>
      <img src={source} className="img-fluid" onLoad={() => setLoaded(true)} />
    </div>
  )
}
export default CustomImg

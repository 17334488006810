import React from "react"
import ActionButton from "./../layout/ActionButton"
var classNames = require("classnames")

class ProjectFooter extends React.Component {
  constructor(props) {
    super(props)
    if (this.props.similar) {
      const { projectsData, project } = this.props

      let similarities = {}

      for (let [key, value] of Object.entries(projectsData)) {
        if (value.id != project.id) {
          // console.log(key, value, project.id)
          const arrayToCompare = value.content.tags.split(", ")
          const sourceOfTruth = project.content.tags.split(", ")
          similarities[key] = sourceOfTruth.filter(value =>
            arrayToCompare.includes(value)
          ).length
        }
      }
      let similarProjects = {}
      for (let [key, value] of Object.entries(similarities)) {
        if (value > 0) {
          similarProjects[key] = projectsData[key]
        }
      }

      if (
        Object.entries(similarProjects).length !== 0 &&
        similarProjects.constructor === Object
      ) {
        let keysSorted = Object.keys(similarProjects).sort(function(a, b) {
          return similarProjects[b] - similarProjects[a]
        })

        let similarProjectsData = []

        for (const projectId of keysSorted.slice(0, 2)) {
          similarProjectsData.push(projectsData[projectId])
        }
        this.state = {
          similarProjectsData: similarProjectsData
        }
      } else {
        this.state = {
          similarProjectsData: false
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { project, similar } = this.props
    const { project: prevProject } = prevProps
    if (similar) {
      if (prevProject != project) {
        this.updateSimilarProjects()
      }
    }
  }

  updateSimilarProjects() {
    const { projectsData, project } = this.props

    let similarities = {}

    for (let [key, value] of Object.entries(projectsData)) {
      if (value.id != project.id) {
        const arrayToCompare = value.content.tags.split(", ")
        const sourceOfTruth = project.content.tags.split(", ")
        similarities[key] = sourceOfTruth.filter(value =>
          arrayToCompare.includes(value)
        ).length
      }
    }
    let similarProjects = {}
    for (let [key, value] of Object.entries(similarities)) {
      if (value > 0) {
        similarProjects[key] = projectsData[key]
      }
    }

    if (
      Object.entries(similarProjects).length !== 0 &&
      similarProjects.constructor === Object
    ) {
      let keysSorted = Object.keys(similarProjects).sort(function(a, b) {
        return similarProjects[b] - similarProjects[a]
      })

      let similarProjectsData = []

      for (const projectId of keysSorted.slice(0, 2)) {
        similarProjectsData.push(projectsData[projectId])
      }

      this.setState({
        similarProjectsData: similarProjectsData
      })
    } else {
      this.setState({
        similarProjectsData: false
      })
    }
  }

  render() {
    const { similar, text, isMobile } = this.props
    let similarProjectsWholeDiv
    if (similar && this.state.similarProjectsData) {
      const { similarProjectsData } = this.state
      let similarProjectsDivs = []

      similarProjectsDivs = (
        <>
          {similarProjectsData.map((project, index) => (
            <ActionButton
              key={index}
              text={
                <>
                  {project.content.title}
                  <span>↳</span>
                </>
              }
              handleClick={() => {
                this.props.openProject(project)
              }}
              additionalClassNames={"display-2 h1 d-block"}
            />
          ))}
        </>
      )

      similarProjectsWholeDiv = (
        <div className="similar-projects">
          <p className="text-uppercase">Похожие проекты</p>{" "}
          <div className={!isMobile ? "text-center" : ""}>
            {similarProjectsDivs}
          </div>
        </div>
      )
    }
    const classes = classNames({
      "project-footer": true,
      "text-uppercase": isMobile
    })
    return (
      <footer className={classes}>
        {similar && similarProjectsWholeDiv}
        <p>{text}</p>
        <ActionButton
          text="↳ НАПИШИТЕ НАМ"
          additionalClassNames="d-inline-block"
          handleClick={this.props.handleClick}
        />
      </footer>
    )
  }
}

export default ProjectFooter

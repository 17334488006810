import React, { useContext } from "react"
import LangContext from "./../LangContext"

const ProjectsTags = React.memo(function Subproject(props) {
  // берём сырые теги с бекенда
  const isRussian = useContext(LangContext)
  const { tags, filter } = props
  const rawTags = tags
    .split(/^-\s*\n*\s*tag_name:\s*>?/gm)
    .filter(Boolean)
    .map(function(string) {
      return string
        .replace(/\n?\s+\n?/gm, " ")
        .trim()
        .replace(/\s+/g, " ") // заменяем на обычный пробел, чтобы правильно сравнивать
    })

  let legitTags = []

  const projectnames = appdata.projects.children
  projectnames.map((projectname, index) => {
    const project = appdata[projectname]
    if (project.content != false) {
      const projectTags = project.content.tags.split(", ")
      const intersection = rawTags.filter(value => projectTags.includes(value))
      legitTags.push(...intersection)
    }
  })

  let projectTags = []
  const uniqLegitTags = [...new Set(legitTags)]

  const allProjectsTag = isRussian ? "Все проекты" : "All projects"
  projectTags.push(
    <div
      key={-1}
      className={filter == "" ? "active" : ""}
      onClick={() => {
        props.clickOnDaTag("")
      }}
    >
      {allProjectsTag}
    </div>
  )

  uniqLegitTags.map((tag, index) => {
    projectTags.push(
      <div
        key={index}
        className={filter == tag ? "active" : ""}
        onClick={() => {
          props.clickOnDaTag(tag)
        }}
      >
        {tag}
      </div>
    )
  })

  return (
    <div className="project-meta__tags project-meta__tags_filter  small d-flex">
      {projectTags.reduce(
        (acc, x) => (acc === null ? [x] : [acc, "\u00a0.\u00a0", x]),
        null
      )}
    </div>
  )
})

export default ProjectsTags

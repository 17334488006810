import React from "react"

class SidewaysHeader extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { isMobile, opened, title } = this.props
    if (isMobile) {
      return (
        <aside
          className="sideways-header justify-content-between"
          onClick={this.props.handleClick}
        >
          <h1>{title}</h1>
          <div className="font-weight-light">{opened ? "↥" : "↧"}</div>
        </aside>
      )
    } else {
      return (
        <aside className="sideways-header">
          <h1>{title}</h1>
        </aside>
      )
    }
  }
}

export default SidewaysHeader

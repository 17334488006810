import React, { useContext } from "react"
import LinkButton from "./LinkButton"
import LangContext from "./../LangContext"

function Footer(props) {
  const { isMobile, english } = props
  const { link_1, link_2, link_3, tagline } = props.content
  const isRussian = useContext(LangContext)

  const links = (
    <>
      <LinkButton anchor={link_1} text="Instagram" />
      <LinkButton anchor={link_2} text="Facebook" />
      <LinkButton anchor={"mailto:" + link_3} text={link_3} />
    </>
  )

  const langLink = isRussian ? "/en" : "/"
  const langText = isRussian ? "EN" : "RU"

  if (!isMobile) {
    return (
      <footer className="footer d-flex text-uppercase">
        <p className="col-5 footer__tagline mb-0">{tagline}</p>
        <div className="d-flex flex-column align-items-start col-5 offset-1">
          {links}
        </div>
        <div className="col-4 text-right align-self-end footer__lang">
          {english && (
            <a href={langLink} className="d-inline-block skewHover text-right">
              {langText}
            </a>
          )}
        </div>
      </footer>
    )
  } else {
    return (
      <footer className="d-flex flex-column footer text-uppercase">
        {links}
        <div className="d-flex footer__credits justify-content-between align-items-end">
          {english && (
            <a href={langLink} className="text-right">
              {langText}
            </a>
          )}

          <p className="small mb-0">
            DESIGNED BY&nbsp;
            <a href="https://orientir.studio">ORIENTIR</a>
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer

import React from "react"
import Helmet from "react-helmet"
const classNames = require("classnames")
import SidewaysHeader from "./../SidewaysHeader"
import ProjectHeader from "./ProjectHeader"
import ProjectBody from "./ProjectBody"
import CloseButton from "./../layout/CloseButton"

class ProjectContainer extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }
  componentDidUpdate(prevProps) {
    const { project, active } = this.props
    const { project: prevProject } = prevProps
    if (prevProject != project || active) {
      this.myRef.current.scrollTop = 0
    }
  }
  render() {
    const { active, project, halfOpened, isMobile } = this.props
    const { content } = project

    const sectionClass = classNames({
      "section section_project": true,
      section_opened: active,
      "section_half-opened": halfOpened && !isMobile,
      "col-14": !isMobile,
      "d-none": isMobile && !active
    })

    const projectContent = (
      <>
        <ProjectHeader isMobile={isMobile} content={content} />
        <ProjectBody project={project} isMobile={isMobile} />
        {this.props.children}
      </>
    )

    return (
      <section
        className={sectionClass}
        onTransitionEnd={this.props.handleTransitionEnd}
        ref={isMobile ? this.myRef : ""}
      >
        {!isMobile && <SidewaysHeader title={content.projectname} />}
        {active && <Helmet title={content.projectname} />}
        <div className="inner project" ref={!isMobile ? this.myRef : ""}>
          {isMobile && <CloseButton handleClick={this.props.closeProject} />}
          {projectContent}
          {isMobile && <CloseButton handleClick={this.props.closeProject} />}
        </div>
      </section>
    )
  }
}

export default ProjectContainer

import React, { useContext } from "react"
import ActionButton from "../layout/ActionButton"
import LangContext from "./../LangContext"
import Img from "react-image"

function SubprojectOpening(props) {
  const { cover, title, short_projectname } = props.subproject.content
  const { url } = props.subproject
  const { isMobile, opened } = props
  const imgSrc = url + "/" + cover.replace(/-\s>*\n*\s*/g, "")
  const isRussian = useContext(LangContext)
  const moreDetails = isRussian ? "ПОДРОБНЕЕ ↧" : "SEE MORE ↧"
  const lessDetails = isRussian ? "ПОДРОБНЕЕ ↥" : "SEE LESS ↥"
  const detailsButton = isRussian ? "Подробнее ↳" : "See more ↳"

  if (!isMobile) {
    return (
      <div className="subproject__opening align-items-center">
        <div className="subproject__photo" onClick={props.toggleOpened}>
          <div className="aspectRatio3by4">
            <Img src={imgSrc} alt={title} className="img-fluid" />
          </div>
        </div>
        <div className="text-center">
          <h1 className="display-1" onClick={props.toggleOpened}>
            {title}
          </h1>
          <ActionButton
            text={opened ? lessDetails : moreDetails}
            handleClick={props.toggleOpened}
            additionalClassNames="d-inline-block"
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className="subproject__opening d-flex overflow-hidden">
        <div className="sideways-header-mobile">
          <h1 onClick={props.toggleOpened} className="display-1">
            {short_projectname}
          </h1>
        </div>
        <div className="subproject__cover-mobile">
          <div className="subproject__photo" onClick={props.toggleOpened}>
            <div className="aspectRatio3by4">
              <Img src={imgSrc} alt={title} className="img-fluid" />
            </div>
          </div>
          <ActionButton text={detailsButton} handleClick={props.toggleOpened} />
        </div>
      </div>
    )
  }
}

export default SubprojectOpening

import React, { useContext } from "react"
import App from "./App"
import LangContext from "./components/LangContext"
import { BrowserRouter as Router } from "react-router-dom"

function AppWithRouter() {
  const isRussian = useContext(LangContext)
  let basepath = !isRussian ? "/en" : "/"
  return (
    <Router basename={basepath}>
      <App />
    </Router>
  )
}

export default AppWithRouter

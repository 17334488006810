import React from "react"
import Subproject from "./Subproject"

const Subprojects = React.memo(function Subprojects(props) {
  const { project, isMobile, appdata } = props

  let children = []
  if (project.children.length > 0) {
    let childrenNames = []
    childrenNames = project.children
    childrenNames.forEach(projectname => {
      if (appdata[projectname].content != false) {
        children.push(appdata[projectname])
      }
    })
  }

  return (
    <>
      {children.map((subproject, index) => (
        <Subproject isMobile={isMobile} subproject={subproject} key={index} />
      ))}
    </>
  )
})

export default Subprojects

import React from "react"
import { render } from "react-dom"
import AppWithRouter from "./AppWithRouter"

// console.log("load start")
// window.onload = function() {
//   console.log("onload")
// }

render(<AppWithRouter />, document.getElementById("app-root"))

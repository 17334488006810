import React from "react"
var classNames = require("classnames")
import SidewaysHeader from "./SidewaysHeader"
import LinkButton from "./layout/LinkButton"
import NewsForm from "./components/NewsForm"
const ReactMarkdown = require("react-markdown")
import LangContext from "./LangContext"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const AboutMarkdown = React.memo(function AboutMarkdown(props) {
  const { about_text } = props
  return (
    <ReactMarkdown
      className="markdown"
      source={about_text}
      allowedTypes={["text", "break", "link", "paragraph"]}
    />
  )
})

class About extends React.Component {
  constructor(props) {
    super(props)
    this.onMouseClickHandler = this.onMouseClickHandler.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    const { active, tighten, isMobile } = this.props
    const {
      active: nextActive,
      tighten: nextTighten,
      isMobile: nextIsMobile
    } = nextProps
    return (
      nextActive != active || nextTighten != tighten || nextIsMobile != isMobile
    )
  }

  onMouseClickHandler() {
    const { index } = this.props
    this.props.handleClick(index)
  }

  render() {
    const { active, tighten, isMobile } = this.props
    const { about_email, about_text } = this.props.content
    const isRussian = this.context

    const sectionClass = classNames({
      "section section_who-we-are": true,
      section_opened: active,
      section_tighten: active && tighten,
      "col-7": !isMobile
    })

    const who_we_are_text = isRussian ? "Кто мы" : "Who we are"
    const write_to_us_text = isRussian ? "Напишите нам" : "Write to us"
    const url =
      "https://placeddmm.us20.list-manage.com/subscribe/post?u=ad2a57af1af28b5021279fc94&id=362eb45a72"

    return (
      <section
        className={sectionClass}
        onClick={!isMobile ? this.onMouseClickHandler : null}
      >
        <SidewaysHeader
          opened={active}
          isMobile={isMobile}
          title={who_we_are_text}
          handleClick={this.onMouseClickHandler}
        />
        <div className="d-flex flex-column inner justify-content-between">
          <div className="section_who-we-are__body">
            <AboutMarkdown about_text={about_text} />
            <LinkButton
              anchor={"mailto:" + about_email}
              text={write_to_us_text}
            />
          </div>

          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <NewsForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </div>
      </section>
    )
  }
}

About.contextType = LangContext

export default About

import React from "react"
import PeopleAndPlaces from "./../components/PeopleAndPlaces"
import CustomImg from "./../components/CustomImg"
function ProjectBody(props) {
  const { content, url } = props.project
  const { project_summary: peopleAndPlaces, blocks } = content

  let contentThings = []
  let contentThingsDivs = []

  if (blocks) {
    let contentPiecesRaw = blocks.split(/-\s\n/g).filter(Boolean)

    contentPiecesRaw.map(property => {
      const cleanProperty = property.replace(
        /\n?\s*_key:.*\n?\s*_uid:.*\n?/,
        ""
      )
      const first = /-?\s?\n?\s*(src|text):/.exec(cleanProperty)[1]

      const second = cleanProperty
        .replace(/\s*(src:|text:)+(\s+)?(\-\s)?>?\n?"?/, "")
        .replace(/\n*\s+/g, " ")
        .replace(/"?$/g, "")
        .trim()
      contentThings.push([first, second])
    })
    contentThings.forEach(function(element, index) {
      const key = element[0]
      const value = element[1]

      if (key == "text") {
        contentThingsDivs.push(
          <p className="project__paragraph mb-0" key={index}>
            {value}
          </p>
        )
      } else {
        contentThingsDivs.push(
          <CustomImg src={url + "/" + value} key={index} />
        )
      }
    })
  }

  return (
    <>
      {peopleAndPlaces && (
        <PeopleAndPlaces peopleAndPlacesRaw={peopleAndPlaces} />
      )}
      {contentThingsDivs}
      {/* <article className="project__content">{contentThingsDivs}</article> */}
    </>
  )
}

export default ProjectBody

import React from "react"

class ActionButton extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { text, additionalClassNames: classes } = this.props
    return (
      <a
        className={"action-button text-uppercase " + (classes || "")}
        onClick={this.props.handleClick}
      >
        {text}
      </a>
    )
  }
}

export default ActionButton

import React from "react"
import ActionButton from "../layout/ActionButton"
const ReactMarkdown = require("react-markdown")
import LangContext from "./../LangContext"

class ProjectOpening extends React.Component {
  static contextType = LangContext
  constructor(props) {
    super(props)
    this.openProject = this.openProject.bind(this)
    this.requestProjectChange = this.requestProjectChange.bind(this)
  }

  openProject() {
    const { project } = this.props
    this.props.openProject(project)
  }

  requestProjectChange() {
    const { project } = this.props
    this.props.requestProjectChange(project)
  }

  render() {
    const { project, isMobile } = this.props
    const { projectname, description, cover } = project.content
    const isRussian = this.context

    const moreText = isRussian ? "↳" : "↳"
    const moreTextMobile = isRussian ? "подробнее ↳" : "see more ↳"

    const imgSrc = project.url + "/" + cover.substring(2)
    const markdownDescription = (
      <ReactMarkdown
        className="markdown"
        source={description}
        renderers={{
          link: props => (
            <a href={props.href} target="_blank">
              {props.children}
            </a>
          )
        }}
      />
    )

    if (!isMobile) {
      return (
        <div
          className="project-opening"
          onMouseEnter={this.requestProjectChange}
        >
          <div className="project__photo">
            <div className="aspectRatio3by4" onClick={this.openProject}>
              <img src={imgSrc} alt={projectname} />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center project-info  text-center text-uppercase">
            <div onClick={this.openProject}>
              <h1 className="display-1">{projectname}</h1>
            </div>
            <div>
              {markdownDescription}
              <ActionButton
                additionalClassNames="btn btn-link btn-block text-uppercase text-center"
                text={moreText}
                handleClick={this.openProject}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="project-opening d-flex">
          <div className="sideways-header-mobile">
            <h1 className="" onClick={this.openProject}>
              {projectname}
            </h1>
          </div>
          <div className="d-flex flex-column justify-content-between project-info text-uppercase">
            <div className="project__photo" onClick={this.openProject}>
              <div className="aspectRatio3by4">
                <img src={imgSrc} alt={projectname} />
              </div>
            </div>
            <ActionButton
              text={moreTextMobile}
              handleClick={this.openProject}
            />
          </div>
        </div>
      )
    }
  }
}

export default ProjectOpening

import React from "react"

function PeopleAndPlaces(props) {
  const peopleAndPlacesRaw = props.peopleAndPlacesRaw
    .split(/-\s*\n/)
    .filter(Boolean)
  let peopleAndPlaces = new Map()
  peopleAndPlacesRaw.map(property => {
    const firstAndSecond = property
      .trim()
      .split(/^\s*\w*:\s?/gm)
      .filter(Boolean)
    let [first, second] = firstAndSecond
    first = first
      .replace(/\n/g, "")
      .replace(">", "")
      .replace(/\s\s+/g, " ")
      .replace(/^"|'/g, "")
      .replace(/"|'$/g, "")
      .trim()
    second = second
      .replace(/\n/g, "")
      .replace(/\s\s+/g, " ")
      .replace(">", "")
      .replace(/^"|'/g, "")
      .replace(/"|'$/g, "")
      .trim()

    peopleAndPlaces.set(first, second)
  })

  let structure = []
  if (peopleAndPlaces.size > 0) {
    peopleAndPlaces.forEach(function(key, value) {
      structure.push(
        <div className="d-flex" key={value}>
          <p className="mb-0 people-places__property text-right"> {value} </p>
          <p className="mb-0 people-places__name"> {key} </p>
        </div>
      )
    })
  }

  return <div className="people-places">{structure}</div>
}

export default PeopleAndPlaces

import React, { useState, useContext } from "react"
import { useForm } from "react-hook-form"
import LangContext from "./../LangContext"

const NewsForm = React.memo(function NewsForm({
  status,
  message,
  onValidated
}) {
  const { register, handleSubmit, errors } = useForm()
  const csrf = document.querySelector("input[name=csrf_token]").value

  const isRussian = useContext(LangContext)

  const onSubmit = data => {
    const email = data.email
    onValidated({
      EMAIL: email
    })
  }

  const thanksText = isRussian
    ? "Спасибо! Вы подписаны на наши новости"
    : "Thanks! You are now subscribed to our newsletter"
  const sendFormText = isRussian
    ? "ПОДПИСАТЬСЯ НА НОВОСТИ"
    : "SUBSCRIBE TO NEWS"
  const sendingText = isRussian ? "Отправляем" : "Sending"

  let formMessage
  switch (status) {
    case "success":
      formMessage = thanksText
      break
    case "error":
      formMessage = message
      break
    case "sending":
      formMessage = sendingText

      break
    default:
      formMessage = sendFormText
  }

  return (
    <div className="subscribe-form">
      <p
        className={
          "text-uppercase mb-0 " + (status === "success" ? "form-success" : "")
        }
      >
        <span dangerouslySetInnerHTML={{ __html: formMessage }} />
      </p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={status === "success" ? "d-none" : ""}
      >
        <div className="d-flex">
          <>
            <input
              name="email"
              type="email"
              className={"form-control " + (errors.email && "is-invalid")}
              id="exampleFormControlInput1"
              placeholder="E-mail"
              ref={register({
                required: true,
                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              })}
            />
            <div className={"border-btm " + (errors.email && "is-invalid")}>
              <button
                type="submit"
                className="btn btn-link skewHover text-right"
              >
                OK
              </button>
            </div>
          </>
        </div>
        <input type="hidden" name="csrf_token" value={csrf} />
        <input
          type="text"
          name="website"
          className="uniform__potty"
          tabIndex="-1"
          autoComplete="off"
        />
      </form>
    </div>
  )
})

export default NewsForm

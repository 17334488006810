import React from "react"
const closeIcon = require("./../../icons/close.svg")
import InlineSVG from "svg-inline-react"
import ActionButton from "./ActionButton"

class CloseButton extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    let actionText = (
      <>
        <InlineSVG src={closeIcon} raw={true} />
        <span className="text">&nbsp;закрыть</span>
      </>
    )
    return (
      <ActionButton
        additionalClassNames="action-button_close d-block text-center"
        text={actionText}
        handleClick={() => {
          this.props.handleClick()
        }}
      />
    )
  }
}

export default CloseButton

import React from "react"
import Footer from "./layout/Footer"
var classNames = require("classnames")

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleLoaded = this.handleLoaded.bind(this)
  }
  handleClick() {
    const { isMobile } = this.props
    if (!isMobile) {
      this.props.handleClick(0)
    }
  }

  handleLoaded() {
    // console.log("loaded")
  }

  shouldComponentUpdate(nextProps) {
    const { isMobile, faded } = this.props
    const { isMobile: nextIsMobile, faded: nextFaded } = nextProps
    return isMobile != nextIsMobile || faded != nextFaded
  }

  render() {
    const { content, isMobile, faded, english } = this.props
    let coverMediaUrl = [
      content["url"],
      content["slug"],
      content["content"]["cover"].replace(/^-?\s*>?\n?\s*/, "")
    ].join("/")

    let isVideo = coverMediaUrl.split(".").pop() == "mp4"

    const coverMediaUrlElement = (
      <div className="media-format">
        {isVideo ? (
          <video
            src={coverMediaUrl}
            alt="Love Buro cover video"
            playsInline
            loop
            autoPlay
            muted
            type="video/mp4"
          />
        ) : (
          <img
            src={coverMediaUrl}
            alt="Love Buro cover image"
            className="img-fluid"
            onLoad={this.handleLoaded}
          />
        )}
      </div>
    )

    const classes = classNames({
      "hero-block ": true,
      "d-flex flex-column justify-content-between overflow-auto": !isMobile,
      "text-center": isMobile,
      "hero-block_faded": faded && !isMobile
    })
    return (
      <main className={classes} onClick={this.handleClick}>
        <div className="hero-block__header">
          {isMobile > 0 && (
            <p className="text-uppercase">{content.content.tagline}</p>
          )}
          <h1 className="hero-block__logo align-self-start text-left">
            LOVE
            <br />
            BURO
          </h1>
        </div>
        {!isMobile > 0 && (
          <div className="hero-block__helper"> </div>
        )}
        <div className="hero-block__cover">{coverMediaUrlElement}</div>

        {!isMobile > 0 && (
          <Footer
            english={english}
            content={content.content}
            isMobile={isMobile}
          />
        )}
      </main>
    )
  }
}

export default Main

import React from "react"
import Helmet from "react-helmet"
import ProjectOpenings from "./project/ProjectOpenings"
import ProjectsTags from "./project/ProjectsTags"
import SidewaysHeader from "./SidewaysHeader"
var classNames = require("classnames")

class Projects extends React.Component {
  constructor(props) {
    super(props)
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this)
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this)
    this.onMouseClickHandler = this.onMouseClickHandler.bind(this)
    this.requestProjectChange = this.requestProjectChange.bind(this)
    this.openProject = this.openProject.bind(this)
    this.setHover = this.setHover.bind(this)
    this.clickOnDaTag = this.clickOnDaTag.bind(this)
    this.state = {
      filter: "",
      isHovered: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { active, tighten, isMobile, transitioning, isHovered } = this.props
    const { filter } = this.state
    const { filter: nextFilter, isHovered: nextIsHovered } = nextState
    if (filter != nextFilter) {
      return true
    }
    const {
      active: nextActive,
      isMobile: nextIsMobile,
      tighten: nextTighten,
      transitioning: nextTransitioning
    } = nextProps
    return (
      isMobile != nextIsMobile ||
      nextActive != active ||
      transitioning != nextTransitioning ||
      tighten != nextTighten ||
      isHovered != nextIsHovered
    )
  }

  setHover(hover) {
    this.setState({ isHovered: hover })
  }

  onMouseEnterHandler() {
    const { index, active } = this.props
    if (!active) {
      this.setHover(true)
      this.props.handleMouseEnter(index)
    }
  }

  onMouseLeaveHandler() {
    const { index, active } = this.props
    if (!active) {
      this.setHover(false)
      this.props.handleMouseLeave(index)
    }
  }

  onMouseClickHandler(e) {
    const { index, active, isMobile } = this.props
    if (!active || (isMobile && e.target.tagName != "A")) {
      this.props.handleClick(index)
      this.onMouseLeaveHandler()
    }
  }

  requestProjectChange(project) {
    const { active } = this.props
    if (active) {
      this.props.requestProjectChange(project)
    }
  }
  openProject(project) {
    const { active, transitioning } = this.props
    if (active && !transitioning) {
      this.props.openProject(project)
    }
  }

  clickOnDaTag(tag) {
    const { filter } = this.state
    this.setState({
      filter: tag == filter ? "" : tag
    })
  }

  render() {
    const {
      active,
      appdata,
      tighten,
      isMobile,
      transitioning,
      tags
    } = this.props
    const { filter, isHovered } = this.state
    const { title } = appdata.projects.content
    const sectionClass = classNames({
      "section section_initiatives": true,
      section_opened: active,
      section_tighten: active && tighten,
      "col-15": !isMobile,
      hover: isHovered,
      section_transition: transitioning && !isMobile
    })

    return (
      <section
        onTransitionEnd={this.props.handleTransitionEnd}
        className={sectionClass}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        onClick={!isMobile ? this.onMouseClickHandler : null}
      >
        {active && <Helmet title={title} />}
        <SidewaysHeader
          title={title}
          isMobile={isMobile}
          opened={active}
          handleClick={this.onMouseClickHandler}
        />
        <div className="inner">
          {tags && (
            <ProjectsTags
              tags={tags}
              filter={filter}
              clickOnDaTag={this.clickOnDaTag}
            />
          )}
          <ProjectOpenings
            appdata={appdata}
            isMobile={isMobile}
            filter={filter}
            hoverProject={this.props.hoverProject}
            unhoverProject={this.props.unhoverProject}
            openProject={this.openProject}
            requestProjectChange={this.requestProjectChange}
          />
        </div>
      </section>
    )
  }
}

export default Projects

import React, { useState, useContext } from "react"
import ProjectBody from "./ProjectBody"
import ProjectAbout from "./ProjectAbout"
import SubprojectOpening from "./SubprojectOpening"
import ActionButton from "../layout/ActionButton"
import ProjectHeader from "./ProjectHeader"
import LangContext from "./../LangContext"

const Subproject = React.memo(function Subproject(props) {
  const [opened, setOpened] = useState(false)
  const { subproject, isMobile } = props
  const isRussian = useContext(LangContext)
  const comeback = isRussian ? "↤ Назад" : "↤ Back"
  const collapseText = isRussian ? "СВЕРНУТЬ ↥" : "COLLAPSE ↥"

  function customSetOpened() {
    setOpened(!opened)
    // console.log("HEEE")
    if (!opened && isMobile) {
      // Dirty monkey patching !!!
      document.querySelector(
        "#app-root > section.section.section_project.section_opened"
      ).scrollTop = 0
    }
  }

  return (
    <div className="subproject">
      <SubprojectOpening
        toggleOpened={() => customSetOpened()}
        subproject={subproject}
        isMobile={isMobile}
        opened={opened}
      />
      {opened && (
        <>
          {isMobile ? (
            <div className="section section_project section_opened">
              <ActionButton
                handleClick={() => setOpened(!opened)}
                text={comeback}
                additionalClassNames="d-block text-center"
              />
              <ProjectHeader isMobile={isMobile} content={subproject.content} />
              <ProjectBody project={subproject} />
              <ActionButton
                handleClick={() => setOpened(!opened)}
                text={comeback}
                additionalClassNames="d-block text-center"
              />
            </div>
          ) : (
            <>
              <ProjectAbout
                about={subproject.content.about}
                link_url={subproject.content.link_url}
              />
              <ProjectBody project={subproject} />
            </>
          )}
          <ActionButton
            text={collapseText}
            additionalClassNames="d-block text-center"
            handleClick={() => setOpened(!opened)}
          />
        </>
      )}
    </div>
  )
})

export default Subproject

import React from "react"
import Helmet from "react-helmet"
var classNames = require("classnames")
import SidewaysHeader from "./SidewaysHeader"

class Services extends React.Component {
  constructor(props) {
    super(props)
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this)
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this)
    this.onMouseClickHandler = this.onMouseClickHandler.bind(this)

    let servicesData = []
    const dividedData = this.props.content.services
      .replace(/ +/g, " ")
      .replace(/\n/g, "")
      .split("-")
      .filter(Boolean)

    dividedData.forEach(function(line) {
      line = line.trim()
      if (line.includes("service_head")) {
        let nameToWorkWith = line
          .replace("service_head: ", "")
          .replace("subservices:", "")
          .replace(">", "")
          .trim()
        servicesData.push([nameToWorkWith])
      } else {
        const subservice = line
          .replace("subservice:", "")
          .replace(">", "")
          .trim()
        servicesData[servicesData.length - 1].push(subservice)
      }
    })

    this.state = {
      servicesData: servicesData
    }
  }

  shouldComponentUpdate(nextProps) {
    const { active, tighten, isMobile } = this.props
    const {
      active: nextActive,
      tighten: nextTighten,
      isMobile: nextIsMobile
    } = nextProps
    return (
      nextActive != active || nextTighten != tighten || nextIsMobile != isMobile
    )
  }

  onMouseEnterHandler() {
    const { index, active } = this.props
    if (!active) {
      this.props.handleMouseEnter(index)
    }
  }

  onMouseLeaveHandler() {
    const { index, active } = this.props
    if (!active) {
      this.props.handleMouseLeave(index)
    }
  }

  onMouseClickHandler() {
    const { index } = this.props
    this.props.handleClick(index)
    this.onMouseLeaveHandler()
  }

  render() {
    const { active, tighten, isMobile, content } = this.props
    const { servicesData } = this.state
    const sectionClass = classNames({
      "section section_services": true,
      section_opened: active,
      section_tighten: active && tighten,
      "col-7": !isMobile
    })

    let services = []
    servicesData.forEach(function(service, index) {
      let subservices = []

      service.slice(1).forEach(function(subservice, index) {
        subservices.push(<li key={index}>{subservice}</li>)
      })
      services.push(
        <li key={index} className="">
          <span>{service[0]}</span>
          <ul>{subservices}</ul>
        </li>
      )
    })

    return (
      <section
        className={sectionClass}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        onClick={!isMobile ? this.onMouseClickHandler : null}
      >
        {active && <Helmet title={content.title} />}
        <SidewaysHeader
          title={content.title}
          isMobile={isMobile}
          opened={active}
          handleClick={this.onMouseClickHandler}
        />
        <div className="inner">
          <ul className="list-unstyled">{services}</ul>
        </div>
      </section>
    )
  }
}

export default Services
